<template>
  <div>
    <v-row>
      <v-col cols="6">
        <p class="mt-4 text-h6  secondary--text">
          {{ currentPhase?.nombre }}
        </p>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <p>
              Fecha de Inicio:
              {{ moment(currentPhase?.fecha_inicio).format("DD/MM/YYYY") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p>
              Fecha de Fin:
              {{ moment(currentPhase?.fecha_fin).format("DD/MM/YYYY") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="currentPhase?.id_fase === 2">
      <RecepcionOfertas />
    </template>
    <template v-if="currentPhase?.id_fase === 3">
      <EvaluacionOfertas />
    </template>
    <template v-if="currentPhase?.id_fase === 4">
      <ResultadoOfertas />
    </template>
    <template v-if="currentPhase?.id_fase === 1">
      <BasesProcesoComponent />
    </template>
  </div>
</template>

<script>
import RecepcionOfertas from "./RecepcionOfertasComponent.vue";
import EvaluacionOfertas from "./EvaluacionOfertasComponent.vue";
import ResultadoOfertas from "./ResultadosComponent.vue";
import BasesProcesoComponent from "./BasesProcesoComponent.vue";
import { mapState } from "vuex";
export default {
  name: "EtapaActualComponent",
  components: {
    RecepcionOfertas,
    EvaluacionOfertas,
    ResultadoOfertas,
    BasesProcesoComponent
  },
  data: () => ({}),
  computed: {
    ...mapState("convenioMarcoProveedor", ["currentAgreement"]),
    currentPhase() {
      if (this.currentAgreement?.fase?.length > 0) {
        return this.currentAgreement.fase[0];
      } else return {};
    },
  },
};
</script>
<template>
  <div class="d-flex flex-column mt-4">
    <p class="text-h6 secondary--text">Listado de OBS que conforman el convenio</p>
    <v-data-table
      :headers="headers"
      :items="currentAgreementDesc"
      hide-default-footer
    >
      <template #[`item.correlativo`]="{ item }">
        {{ currentAgreementDesc.map((col) => col.id).indexOf(item.id) + 1 }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "DescripcionOBSComponent",
  data: () => ({
    headers: [
      {
        text: "Correlativo",
        sortable: false,
        value: "correlativo",
      },
      { text: "Código OBS", align: "center", sortable: false, value: "codigo" },
      {
        text: "Mercancía",
        sortable: false,
        value: "nombre",
      },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", ["currentAgreementDesc"]),
  },
};
</script>
<style lang="scss" scoped>
// Alinea todos los encabezados del componente
:deep(.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th.sortable) {
  text-align: center !important;
}
:deep(.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th) {
  text-align: center !important;
}
</style>
<template>
  <div class="d-flex flex-column mt-4">
    <v-col cols="12" md="12">
      <p class="text-body-2 primary--text">
        En esta etapa la Dirección está evaluando los productos ofertados en el
        convenio. Puede ver sus productos presentados:
      </p>
    </v-col>
    <v-row>
      <v-col cols="12" md="10">
        <presentationsTable :headers="headers" onlyShow subtitle="Productos ofertados" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import presentationsTable from "./PresentationsTableComponent.vue";
export default {
  name: "EvaluacionOfertasComponent",
  components: {
    presentationsTable,
  },
  data: () => ({
    headers: [
      {
        text: "OBS",
        align: "start",
        sortable: false,
        value: "obs",
      },
      {
        text: "Presentación",
        align: "start",
        sortable: false,
        value: "nombre_presentacion",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
  }),
};
</script>
<template>
  <div class="d-flex flex-column mt-4">
    <v-col cols="12" md="10">
      <v-data-table
        :headers="headers"
        :items="currentAgreementDocs"
        hide-default-footer
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{moment(item.created_at).format("DD/MM/YYYY")}}
        </template>
        <template v-slot:[`item.versiones`]="{ item }">
          <v-icon @click="downloadDocument(item.ruta)" color="secondary">
            mdi-download
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "BasesProcesoComponent",
  data: () => ({
    headers: [
      {
        text: "Documento",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Tipo de documento",
        align: "start",
        sortable: false,
        value: "tipo",
      },
      {
        text: "Fecha de carga",
        align: "center",
        sortable: false,
        value: "created_at",
      },
      {
        text: "Versiones",
        align: "center",
        sortable: false,
        value: "versiones",
      },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", ["currentAgreementDocs"]),
  },
  methods: {
    getDocumentName(id) {
      const data = this.lista_document_type.find((item) => item.id === id);
      return data.nombre;
    },
    async downloadDocument(ruta) {
      const response = await this.services.AgreementMarco.getDocumentoBases({
        ruta,
      });
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${ruta}`);
      document.body.appendChild(link);
      link.click();
    },
  },

};
</script>

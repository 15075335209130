<template>
  <div class="d-flex flex-column mt-4">
    <v-col cols="12" md="12">
      <p class="text-body-2 primary--text">
        Si posee al menos un OBS seleccionado, deberá ponerse en contacto con la
        Dirección de Compras para la respectiva firma del convenio.
      </p>
    </v-col>
    <v-col cols="12" md="12">
      <p class="text-caption primary--text">Productos ofertados</p>
      <v-data-table
        :headers="headers"
        :items="agreementPresentations"
        hide-default-footer
      >
        <template v-slot:[`item.obs`]="{ item }">
          {{ item.codigo }} - {{ item.nombre_obs }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip label :color="item.aprobado ? '#C1ECD9' : '#E0E5F1'">
            {{ item.aprobado ? "Seleccionado" : "Rechazado" }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ResultadosComponent",
  data: () => ({
    headers: [
      {
        text: "OBS",
        align: "start",
        sortable: false,
        value: "obs",
      },
      {
        text: "Presentación",
        align: "start",
        sortable: false,
        value: "nombre_presentacion",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "estado",
      },
    ],
    items: [
      {
        obs: "1123332 - Papel bond",
        status: 1,
      },
      {
        obs: "1123332 - Productos de limpieza",
        status: 2,
      },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", ["agreementPresentations"]),
  },
  methods: {},
};
</script>
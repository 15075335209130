<template>
  <section>
    <v-row>
      <v-col cols="12" md="12">
        <v-breadcrumbs
          color="secondary"
          large
          :items="breadcrumbsItems"
        ></v-breadcrumbs>
      </v-col>

      <v-col cols="12" sm="12" :md="perfilCompleto ? 8 : 12" :lg="perfilCompleto ? 8 : 12">
        <detalle-convenio
          v-if="currentAgreement.id"
          :convenio="currentAgreement"
        />
      </v-col>
      <v-col v-if="perfilCompleto" cols="12" sm="12" md="4" lg="4">
        <div class="d-flex justify-end">
          <v-btn
            @click="mostrarTabsInfo = false"
            dark
            v-if="mostrarTabsInfo && currentAgreement?.fase[0]?.id_fase === 2"
            x-large
            color="secondary"
          >
            Participar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col>
        <v-tabs background-color="transparent" v-if="mostrarTabsInfo">
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab>
            <v-icon class="mx-2">mdi-format-list-bulleted</v-icon> Descripción OBS
          </v-tab>
          <v-tab>
            <v-icon class="mx-2">mdi-book-open-variant</v-icon> Documentos del Proceso
          </v-tab>
          <v-tab>
            <v-icon class="mx-2">mdi-format-list-numbered</v-icon> Etapas del Proceso
          </v-tab>
          
          <v-tab-item>
            <DescripcionOBS />
          </v-tab-item>
          <v-tab-item>
            <DocumentosProceso />
          </v-tab-item>
          <v-tab-item>
            <EtapasProceso
              :headers="headersEtapas"
              :items="currentAgreementPhases"
              hideButton
            />
          </v-tab-item>
        </v-tabs>
        <!-- Mostrar etapa actual -->
        <v-tabs background-color="transparent" v-else>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab>
            <v-icon class="mx-2">mdi-clipboard-text</v-icon> Etapa Actual
          </v-tab>
          <v-tab-item>
            <EtapaActual />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DetalleConvenio from "@/components/DetalleConvenioComponent.vue";
import DescripcionOBS from "./components/DescripcionOBSComponent.vue";
import DocumentosProceso from "./components/DocumentosProcesoComponent.vue";
import EtapasProceso from "@/components/EtapasProcesoComponent.vue";
import EvaluacionOfertas from "./components/EvaluacionOfertasComponent.vue";
import Resultados from "./components/ResultadosComponent.vue";
import EtapaActual from "./components/EtapaActualComponent.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "informacionConvenioView",
  components: {
    DetalleConvenio,
    DescripcionOBS,
    DocumentosProceso,
    EtapasProceso,
    EvaluacionOfertas,
    Resultados,
    EtapaActual,
  },
  data: () => ({
    mostrarTabsInfo: false,
    headersEtapas: [
      {
        text: "Etapas",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Fecha de inicio",
        align: "center",
        sortable: false,
        value: "fecha_inicio",
      },
      {
        text: "Fecha de fin",
        align: "center",
        sortable: false,
        value: "fecha_fin",
      },
      {
        text: "Observación",
        align: "center",
        sortable: false,
        value: "observacion",
        width: "35%",
      },
    ],
    perfilCompleto: false,
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", [
      "currentAgreement",
      "currentAgreementPhases",
      "breadcrumbsItems",
      "agreementPresentations",
    ]),
    ...mapState(["userInfo"]),
  },
  methods: {
    ...mapActions("convenioMarcoProveedor", [
      "getCurrentAgreementDesc",
      "getCurrentAgreementDocs",
      "getCurrentAgreementPhases",
      "getCurrentAgreementPhases",
      "getAgreementPresentations",
      "getCurrentAgreement",
    ]),
    setDisplayOption() {
      if (this.currentAgreement?.fase.length > 0) {
        if (this.currentAgreement?.fase[0]?.id_fase !== 2)
          this.mostrarTabsInfo = false;
        else if (
          this.currentAgreement?.fase[0]?.id_fase === 2 &&
          this.agreementPresentations.length > 0
        ) {
          this.mostrarTabsInfo = false;
        } else {
          this.mostrarTabsInfo = true;
        }
      } else {
        this.mostrarTabsInfo = true;
      }
    },
    async getProfilePercentage() {
      let response = await this.services.Proveedores.getProviderPercentage();

      if (response.status == 200) {
        this.perfilCompleto = response?.data.perfil_completo;
      }
    },
  },
  async created() {
    if (this.breadcrumbsItems.length > 1) {
      this.breadcrumbsItems.pop();
    }

    await this.getCurrentAgreement(this.$route.params.id);
    this.getProfilePercentage();

    this.breadcrumbsItems.push({
      text: this.currentAgreement.correlativo,
    });

    Promise.all([
      this.getAgreementPresentations(this.currentAgreement.id),
      this.getCurrentAgreementDesc(this.currentAgreement.id),
      this.getCurrentAgreementDocs(this.currentAgreement.id),
      this.getCurrentAgreementPhases(this.currentAgreement.id),
    ]).then(() => {
      this.setDisplayOption();
    });
  },
};
</script>
<style lang="scss" scoped>
:deep(.theme--light.v-tabs-items) {
  background: transparent !important;
}
.v-breadcrumbs {
  padding-left: 0px !important;
}
</style>

<template>
  <div class="mt-4">
    <v-data-table
      :headers="headers"
      :items="currentAgreementDocs"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="showDocument(item.id)"
              large
              color="primary"
            >
              mdi-file-document-outline
            </v-icon>
          </template>
          <span> Ver Documento </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card class="pb-2">
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-card-actions>
          <v-btn @click.stop="showDialog = false" color="secondary" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "DocumentosProcesoComponent",
  data: () => ({
    headers: [
      {
        text: "Tipo de Documento",
        align: "center",
        sortable: false,
        value: "tipo",
      },
      {
        text: "Visualizar",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    showDialog: false,
    doc: null,
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", ["currentAgreementDocs"]),
  },
  methods: {
    async showDocument(id) {
      let response =
        await this.services.AgreementMarco.getConvenioProveedorOBSDoc(id);
      if (response.status == 200) {
        this.getDoc(response?.data);
        this.showDialog = true;
      }
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
  },
};
</script>
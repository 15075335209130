<template>
  <div>
    <v-row>
      <v-col>
        <v-btn class="secondary" @click="$router.push({name:'ofertar-producto', params:{id:$route.params.id}})">
          Agregar producto
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <presentationsTable :headers="headers" subtitle="Listado de OBS a ofertar" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import presentationsTable from "./PresentationsTableComponent.vue";
import { mapState } from "vuex";
export default {
  name: "RecepcionOfertasComponent",
  components: {
    presentationsTable,
  },
  data: () => ({
    headers: [
      {
        text: "OBS",
        align: "start",
        sortable: false,
        value: "obs",
      },
      {
        text: "Presentación",
        align: "start",
        sortable: false,
        value: "nombre_presentacion",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", ["agreementPresentations"]),
  },
};
</script>